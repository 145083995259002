<template>
  <v-row>
    <v-col
      v-if="!prepopulate"
      cols="12"
    >
      <v-menu
        v-model="showContainersList"
        v-bind="attributes.menu"
        min-width="468"
        max-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="query"
            ref="searchContainerField"
            label="Szukaj kontenera"
            :placeholder="getSearchShortkeysPlaceholder('kontenera')"
            outlined
            clearable
            autocomplete="off"
            v-bind="attrs"
            v-on="on"
            autofocus
            v-shortkey="searchShortkeys"
            @input="searchAddresses"
            @click:clear="clearAddressesList"
            @shortkey.native="focusSearchContainerField"
          >
            <template #prepend-inner>
              <Icon
                name="search"
                size="small"
                class="mr-2"
              />
            </template>
          </v-text-field>
        </template>

        <v-data-table
          ref="resultsTable"
          class="results-table"
          v-model="selectedContainer"
          :headers="resultsTableHeaders"
          :items="containersList"
          :items-per-page="-1"
          :loading="searchProcessing"
          item-key="uniqueKey"
          hide-default-footer
          fixed-header
          single-select
          @click:row="selectContainer"
        />
      </v-menu>
    </v-col>

    <template>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-row class="pl-1 mb-3 mt-n2">
          <v-col
            cols="6"
            class="py-0 mt-1"
          >
            <div class="input-label d-flex align-center text-14">
              <v-input
                :value="orderData.addressId"
                :rules="hideOrder ? [] : [rules.required]"
                class="validated-label"
                hide-details
              >
                Informacje o kliencie:
              </v-input>
              <span
                v-if="chosenClientData.blocked"
                class="red--text d-flex align-center ml-1"
              >
                <img
                  src="@/assets/icons/block-mark.svg"
                  class="mr-1"
                  alt="blocked"
                >
                Klient zablokowany
              </span>
            </div>
            <div class="chosen-client-details">
              {{ chosenClientData.name || '-' }}
            </div>

            <div class="chosen-client-details mt-1">
              {{ chosenClientData.invoiceAddress || '-' }}
            </div>
          </v-col>
          <v-col
            v-for="param in clientInfoParams"
            :key="param.text + 'client'"
            :cols="param.cols"
            class="pt-1 pb-0 pr-0 text-14"
          >
            <div
              class="d-flex"
              :style="{'flex-direction': param.direction ? param.direction : 'row'}"
            >
              <span class="input-label mr-1">{{ param.text }}: </span>
              <span class="chosen-client-details">{{ param.value || '-' }}</span>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="pt-6 pb-0"
          >
            <div class="input-label">
              Informacje o lokalizacji:
            </div>
          </v-col>

          <v-col
            v-for="param in addressInfoParams"
            :key="param.text + 'address'"
            :cols="param.cols"
            class="pt-1 pb-0 pr-0 text-14"
          >
            <span class="input-label mr-1">{{ param.text }}: </span>
            <span class="chosen-client-details">{{ param.value || '-' }}</span>
          </v-col>
          <template v-if="!orderData.anyContainer">
            <v-col
              cols="12"
              class="pt-6 pb-0"
            >
              <div class="input-label">
                Informacje o płatności:
              </div>
            </v-col>

            <v-col
              v-for="param in paymentInfoParams"
              :key="param.text + 'payment'"
              :cols="param.cols"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">{{ param.text }}: </span>
              <span class="chosen-client-details">{{ param.value || '-' }}</span>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </template>

    <template v-if="!prepopulate || fromClientApp">
      <v-col cols="4">
        <v-autocomplete
          outlined
          :items="quantityOptions"
          label="Liczba kontenerów"
          @input="emitUpdate('order.quantity', $event)"
          :value="orderData.quantity"
          :rules="[rules.required]"
          :disabled="!orderData.anyContainer || fromClientApp"
          placeholder="1"
          hide-details
          dense
        />
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          :items="availableContainerTypes"
          :label="containerTypeLabel"
          placeholder="Wybierz typ"
          @input="emitUpdate('order.pickupContainerTypeId', $event)"
          :value="orderData.pickupContainerTypeId"
          validate-on-blur
          hide-details
          :disabled="!orderData.anyContainer"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>

      <v-col
        cols="4"
        class="d-flex align-center"
      >
        <v-checkbox
          :input-value="orderData.anyContainer"
          :disabled="isProcessing || fromClientApp"
          hide-details
          class="my-0 pa-0"
          @change="changeAnyContainerStatus($event)"
        />
        <span
          class="input-label"
          v-text="'Dowolny kontener'"
        />
      </v-col>
    </template>

    <v-col
      cols="12"
      class="mt-2 pb-0"
      v-if="!orderData.anyContainer && !hideOrder"
    >
      <v-autocomplete
        outlined
        :items="sortedOrdersList"
        label="Zlecenie"
        @input="emitUpdate('order.orderId', $event)"
        :value="orderData.orderId"
        :rules="[rules.required]"
        item-disabled="pickupCourseId"
        item-text="parsedData"
        item-value="id"
        placeholder="Wybierz zlecenie"
        append-icon="mdi-chevron-down"
        no-data-text="Brak zleceń dla wybranego klienta"
        :disabled="prepopulate"
        dense
      >
        <template #item="{item, attrs, on}">
          <v-list-item
            v-bind="attrs"
            :disabled="false"
            v-on="on"
          >
            <span :class="{'text--disabled': attrs.disabled}">
              {{ item.parsedData }}
            </span>

            <v-btn
              v-if="attrs.disabled"
              icon
              x-small
              color="primary"
              target="_blank"
              :disabled="!item.pickupCourseId"
              @click="findCourse(item)"
            >
              <v-icon small>
                mdi-arrow-right-bold-box
              </v-icon>
            </v-btn>
          </v-list-item>
        </template>
        <template #prepend-inner>
          <Icon
            name="search"
            size="small"
            class="mr-2 mt-1"
          />
        </template>
      </v-autocomplete>
    </v-col>

    <v-col
      cols="12"
      v-if="isSwap"
    >
      <SuggestedOrderPrice
        :disabled="disableSuggestedOrderPrice"
        :order-data="orderData"
        course-type="Wymiana"
        :client="chosenClientData"
        :selected-address="selectedAddress"
        :search-processing="searchProcessing"
        @populateOrder="populateOrder"
        @populateProduct="populateProduct"
      />
    </v-col>

    <v-col
      class="pt-0"
      v-if="distance"
    >
      <div class="input-label">
        Odległość od bazy w linii prostej: {{ distance }} km
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SuggestedOrderPrice from './SuggestOrderPrice.vue'
import api from '../../../api/v1'
import rules from '../../../utils/validators'
import {
  updateOrderFields,
  updatePaymentFields,
  updateProductFields,
} from '../../../utils/fieldsUpdate'
import attributes from '../../../const/datePickerAttrributes'
import {
  searchShortkeys,
  getSearchShortkeysPlaceholder,
} from '../../../const/shortKeys'
import {
  formatValue
} from '../../../utils/typesEnum'
import { AddressClient, ProductFromLastDoneOrder } from '../../../models'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'

const resultsTableHeaders = [
  { text: 'Klient', value: 'clientName' },
  { text: 'Adres', value: 'formattedAddress' },
  { text: 'Kontener', value: 'refNumberWithType' },
  { text: 'Nr BDO', value: 'clientBdoNumber' },
  { text: 'NIP', value: 'clientInvoiceNip' },
  { text: 'Numer Telefonu', value: 'clientPhoneNumber' },
  { text: 'Utworzono zabranie', value: 'withPickupCourse' },
]

export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
    specificClient: {
      type: Boolean,
      default: false,
    },
    prepopulate: {
      type: Boolean,
      default: false,
    },
    swapBaseOrder: {
      type: Object,
      deault: null,
    },
    distance: {
      type: Number,
      required: false,
    },
    hideOrder: {
      type: Boolean,
      default: false,
    },
    isSwap: {
      type: Boolean,
      default: false,
    },
    isPickup: {
      type: Boolean,
      default: false,
    },
    fromClientApp: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      required: false,
    },
    disableSuggestedOrderPrice: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SuggestedOrderPrice
  },
  data() {
    return {
      rules,
      resultsTableHeaders,
      searchShortkeys,
      attributes,
      query: '',
      clientId: null,
      addressesList: [],
      selectedContainer: [],
      selectedAddress: null,
      searchProcessing: false,
      showContainersList: false,
      isProcessing: false,
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      singleClient: state => state.client.entity,
      ordersList: state => state.orders.simplifiedList,
      populatedOrder: state => state.order.entity,
      newOrderProducts: state => state.orders.newOrderProducts,
    }),
    sortedOrdersList() {
      return [...this.ordersList].sort((a, b) => !!a.pickupCourseId - !!b.pickupCourseId)
    },
    chosenClientData() {
      if (this.specificClient || this.prepopulate) {
        return this.singleClient.id ? this.singleClient : this.populatedOrder.client
      }
      return new AddressClient(this.selectedAddress || {})
    },
    containersList() {
      return this.addressesList
        .map(address => {
          const { id: addressId, formattedAddress, ...restAddress } = address
          return address.containers.map((container, index) => ({
            uniqueKey: `${container.id}-${container.refNumber}-${container.containerTypeId}-${index}`,
            ...container,
            ...restAddress,
            addressId,
            formattedAddress,
            withPickupCourse: container.withPickupCourse ? 'Tak' : 'Nie'
          }))
        })
        .flat()
        .sort((a, b) => !!a.pickupCourseId - !!b.pickupCourseId)
    },
    availableContainerTypes() {
      class ContainerType {
        constructor(container) {
          this.id = container.containerTypeId
          this.name = container.containerTypeName
        }
      }
      // vuetify makes list unique on its own
      return this.selectedAddress?.containers.map(container => { return new ContainerType(container) }) || []
    },
    quantityOptions() {
      // 10 because it is possible to take more containers than exist
      return [...Array(10).keys()].map(number => number + 1)
    },
    clientInfoParams() {
      const {
        invoiceNip,
        phoneNumber,
        bdoNumber,
        bdoType,
        settlementType,
      } = this.chosenClientData

      const { name: populededOrderMiddlemanName } = this.populatedOrder?.middleman || {}
      const { middlemanName: selectedAddressMiddlemanName } = this.selectedAddress?.client || {}

      return [
        { text: 'Pośrednik', value: populededOrderMiddlemanName || selectedAddressMiddlemanName, direction: 'column', cols: 6 },
        { text: 'Telefon', value: phoneNumber, cols: 12 },
        { text: 'Typ dokumentu', value: settlementType, cols: 7 },
        { text: 'Numer NIP', value: invoiceNip, cols: 5 },
        { text: 'Logika BDO', value: bdoType, cols: 7 },
        { text: 'Nr BDO', value: bdoNumber, cols: 5 },
      ]
    },
    addressInfoParams() {
      const { formattedAddress, phoneNumber: addressPhoneNumber, notes } =
        this.selectedAddress?.address || this.selectedAddress || {}
      return [
        { text: 'Adres', value: formattedAddress, cols: 7 },
        { text: 'Telefon', value: addressPhoneNumber, cols: 5 },
        { text: 'Notatka', value: notes, cols: 12 },
      ]
    },
    paymentInfoParams() {
      const { settlementType, paymentType, paymentDueDays, vatPercentage, debrisGrossValue, debrisNetValue } =
        this.paymentData || {}

      return [
        { text: 'Typ rozliczenia', value: settlementType, cols: 4 },
        { text: 'Typ płatności', value: paymentType, cols: 4 },
        { text: 'Termin płatności', value: formatValue(paymentDueDays, 'days'), cols: 4 },
        { text: 'Stawka vat', value: formatValue(vatPercentage, 'percentage'), cols: 4 },
        { text: 'Cena netto', value: formatValue(debrisNetValue, 'price'), cols: 4 },
        { text: 'Cena brutto', value: formatValue(debrisGrossValue, 'price'), cols: 4 },
      ]
    },
    containerTypeLabel() {
      return this.isSwap || this.isPickup ? 'Typ kontenera do zabrania' : 'Typ kontenera'
    }
  },
  created() {
    if (this.specificClient) {
      this.query = this.singleClient.name
      this.searchAddresses()
    }
    if (this.prepopulate) {
      const order = this.swapBaseOrder || this.populatedOrder
      // in case of editing, order field should show base order in any other scenario current order
      this.emitUpdate('order.orderId', order.id)
      const { address, client } = this.template || order
      this.selectedAddress = { ...address, client } // it is required for selectedAddress to have client object
      this.$store.commit('orders/SET_SIMPLIFIED_ITEMS', [order])
    }
    if (this.fromClientApp) {
      this.checkAddressOrdersAvailability()
    }
  },
  beforeDestroy() {
    this.clearSimplifiedOrdersList()
    this.clearOrder()
  },
  watch: {
    'selectedAddress.id': {
      handler() {
        this.getLastDoneOrderProducts()
      },
    },
  },
  methods: {
    ...mapActions({
      getSimplifiedOrders: 'orders/getSimplifiedItems',
      clearSimplifiedOrdersList: 'orders/clearSimplifiedList',
      clearOrder: 'order/clearOrder',
      setTableDate: 'courses/setTableDate',
      fetchCourses: 'courses/getItems',
      showSnackbar: 'snackbar/showSnackbar',
    }),
    getSearchShortkeysPlaceholder,
    async findCourse (order) {
      const { isSwapBase, swapCourseDate, swapCourseId, pickupCourseDate, pickupCourseId } = order
      const date = isSwapBase ? [swapCourseDate] : [pickupCourseDate]
      const activeItemId = isSwapBase ? swapCourseId : pickupCourseId

      this.setTableDate({ date })
      await this.$nextTick()
      if (this.$route.name === 'courses') {
        this.fetchCourses().then(() => {
          this.$router.replace({ query: { activeItemId } })
        })
      } else {
        this.$router.push({ name: 'courses', query: { activeItemId } })
      }
    },
    selectContainer(container, row) {
      row.select(true)
      this.showContainersList = false
      const selectedAddress = this.addressesList.find(
        address => address.id === container.addressId
      )
      this.selectedAddress = {
        ...selectedAddress,
        client: new AddressClient(selectedAddress)
      }
      this.emitUpdate('clientType', selectedAddress.clientClientType)

      const { paymentType, paymentDueDays } = selectedAddress
      if (paymentType) this.emitUpdate('payment.paymentType', paymentType)
      if (paymentDueDays) { this.emitUpdate('payment.paymentDueDays', paymentDueDays) }

      this.emitUpdate('order.addressId', container.addressId)
      this.checkAddressOrdersAvailability()

      this.clearContainerData()
      const params = {
        departmentId: this.department.id,
        swapOrPickup: true,
        filters: {
          done: false,
          clientIds: container?.clientId ? [container.clientId] : undefined,
        },
      }
      const { anyContainer } = this.orderData
      this.isProcessing = true
      this.getSimplifiedOrders({ params }).then(() => {
        const order =
          this.ordersList.find(
            order => !order.pickupCourseId && order.refNumber === container.orderRefNumber
          ) || {}
        if (!anyContainer) {
          this.emitUpdate('order.orderId', order.id)
        } else {
          this.emitUpdate('order.debrisTypeId', order.debrisTypeId)
          this.emitUpdate('order.containerTypeId', order.containerTypeId)
        }
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
    changeAnyContainerStatus(isAnyContainer) {
      this.emitUpdate('order.anyContainer', isAnyContainer)
      if (isAnyContainer) {
        this.emitUpdate('order.orderId', null)
        this.emitUpdate('order.userNotes', null)
        this.checkAddressOrdersAvailability()
      } else {
        this.clearContainerData()
      }
    },
    checkAddressOrdersAvailability() {
      const { anyContainer, addressId } = this.orderData
      if (anyContainer && addressId) {
        this.isProcessing = true
        api
          .checkAddressOrders(addressId)
          .then(resp => {
            const {
              message,
              messageType,
            } = resp.data
            this.showSnackbar({
              message: [message],
              type: messageType,
            })
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    },
    clearContainerData() {
      this.emitUpdate('order.quantity', 1)
      this.emitUpdate('order.pickupContainerTypeId', null)
    },
    clearAddressesList() {
      this.addressesList = []
    },
    searchAddresses: debounce(function() {
      if (this.query) {
        this.searchProcessing = true
        this.showContainersList = true

        const clientId = this.specificClient ? this.singleClient.id : undefined
        api
          .searchAddresses({
            departmentId: this.department.id,
            clientId,
            filters: {
              query: this.query,
            },
          })
          .then(resp => {
            this.addressesList = resp.data
          })
          .finally(() => {
            this.searchProcessing = false
          })
      } else {
        this.clearAddressesList()
      }
    }, 700),
    emitUpdate(key, value) {
      this.$emit('update', { key, value })
    },
    getLastDoneOrderProducts() {
      const { id, client } = this.selectedAddress
      this.isProcessing = true
      api
        .getClientLastDoneOrder(client.id, {
          addressId: id,
          orderType: 'standard',
          done: true
        })
        .then(resp => {
          if (resp.data[0]) {
            // <-- this functionality provides caching data from last done order
            // as products. We add them to products list and if user will select same containerTypeId and debrisTypeId it will be prepopulated
            // with this data with priority
            const products = resp.data?.map(order => new ProductFromLastDoneOrder(order))
            this.$store.commit('orders/ADD_PRODUCTS', products)
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    async populateOrder (order) {
      const data = {
        ...order,
        vatPercentage: 23
      }
      this.emitUpdate('payment.origin', 'Z')
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateOrderFields.call(this, data)
      updatePaymentFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    async populateProduct (product) {
      const data = {
        ...product,
        origin: 'P', // represents 'product' origin
        vatPercentage: 23
      }
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateProductFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    focusSearchContainerField() {
      this.$refs.searchContainerField.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
</style>
